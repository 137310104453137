import React, { useEffect, useContext } from "react";
import { HomeCategory } from "./HomeCategory";
import { Layout } from "../../components/Layout";
import { HomeSlider } from "./HomeSlider";
import { HomeContext } from "../../context/home/HomeState";
import { useSnackbar } from "../../context/general/SnackbarContext";
import { ItemHomeAd } from "./items/ItemHomeAd";
import { useTranslation } from "react-i18next";

export const HomePage = () => {
  const {
    ads,
    newArrivals,
    getNewArrivals,
    getAds,
    featuredCategories,
    getFeaturedCategories,
  } = useContext(HomeContext);

  const { showError } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    getAds();
    getFeaturedCategories();
    getNewArrivals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderNewArrivals = () => {
    const category = {
      category: {
        name: t("newArrivals"),
      },
      products: newArrivals.data,
    };
    return (
      <div className="mt-16">
        <HomeCategory category={category} />
      </div>
    );
  };

  const renderCategoriesAndAds = () => {
    const mAds = ads.data;
    const categories = featuredCategories.data;
    const result = [];
    const maxLength = Math.max(mAds.length, categories.length);

    for (let i = 0; i < maxLength; i++) {
      if (mAds[i]) {
        result.push(<ItemHomeAd ad={mAds[i]} key={`ad-${i}`} />);
      }
      if (categories[i]) {
        result.push(
          <HomeCategory category={categories[i]} key={`category-${i}`} />
        );
      }
    }

    return <div className="flex flex-col space-y-16 mt-16">{result}</div>;
  };

  useEffect(() => {
    if (newArrivals.error) {
      showError(newArrivals.error);
    }
    if (featuredCategories.error) {
      showError(featuredCategories.error);
    }
  }, [newArrivals.error, featuredCategories.error, showError]);

  return (
    <Layout>
      <div className="flex flex-col mt-4">
        <HomeSlider />
        {newArrivals.loading || newArrivals.error ? <></> : renderNewArrivals()}
        {ads.loading ||
        ads.error ||
        featuredCategories.loading ||
        featuredCategories.error ? (
          <></>
        ) : (
          renderCategoriesAndAds()
        )}
      </div>
    </Layout>
  );
};
